module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@2.12.1_gatsby@2.32.13/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Luyu Cheng's Blog","short_name":"Luyu's Blog","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"browser","icon":"src/images/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2901974d14bd50e0c34ed9fd2045e504"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@3.11.1_85c2978793d23fc44de9b94aba6244d7/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@2.10.0_gatsby@2.32.13/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-ts-config@1.1.5_gatsby@2.32.13+typescript@4.3.5/node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"config"},
    }]
